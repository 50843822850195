import React, { useState, useCallback, useMemo } from "react";
import { CustomStreamPlayer } from "../../CustomStreamPlayer";
import { AppUtils } from "../../../utils";

import { COLORS } from "../../../constants";
import { KKButton } from "../../Buttons";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import useNavigate from "../../../useNavigate";
import { useRecoilValue, useRecoilState } from "recoil";
import { accountState, caseState, cognitoUserState } from "../../../atoms";
import { getDay8PlusDays, getToday } from "../../../utils";
import { get, set } from "lodash";
import PaypalPayment from "../../PaypalPayment";
import { KKRadio, InfoBox } from "../../common";
import { produce } from "immer";

const product = {
  id: "praevention_2024-001",
  name: "Onlinekurs: Stoffwechselgesundheit durch Ernährung",
  price: 150,
};

const Comp = () => {
  const account = useRecoilValue(accountState);
  const [pcase, setPcase] = useRecoilState(caseState);
  const [disclaimerDone, setDisclaimerDone] = useState(false);
  const cognitoUser = useRecoilValue(cognitoUserState);
  const goto = useNavigate();
  const [disclaimerElements, setDisclaimerElements] = useState([
    {
      text: "Ich bin mindestens 18 Jahre alt",
      checked: false,
    },
    {
      text: "Mein BMI liegt zwischen 25 und 30 oder mein BMI liegt zwischen 30 und 35 und ich habe die Teilnahme am Präventionskurs mit meinem Arzt abgesprochen",
      checked: false,
    },
    {
      text: "Ich bestätige, dass ich nicht an einer behandlungsbedürftigen Stoffwechselerkrankung (z.B. Diabetes mellitus Typ 2, Schilddrüsen Über- oder Unterfunktion, Fettstoffwechselstörungen) leide",
      checked: false,
    },
    {
      text: "Ich bestätige, dass ich nicht wegen einer psychische (Ess-)Störungen (z.B. Magersucht bzw. Anorexie, Bulimie nervosa, Binge-Eating-Störung) vor in ärztlicher Behandlung bin und das eine solche nicht auch nicht vorliegt",
      checked: false,
    },
  ]);
  const handleDisclaimerChange = useCallback(
    (idx, val) => {
      setDisclaimerElements(
        produce(disclaimerElements, (draft) => {
          draft[idx].checked = val;
        })
      );
    },
    [disclaimerElements]
  );

  let paymentIndex = useMemo(
    () =>
      (get(account, "payments", []) || []).findIndex(
        (payment) => payment.productId === product.id
      ),
    [account]
  );
  let startDate = useMemo(() => {
    if (paymentIndex >= 0) {
      return get(account, `payments.${paymentIndex}.createdAt`, 9999);
    }
    return 0;
  }, [paymentIndex, account]);

  const disclaimerOK = useMemo(() => {
    let ok = true;
    for (let d of disclaimerElements) {
      if (!d.checked) {
        ok = false;
      }
    }
    return ok;
  }, [disclaimerElements]);

  const anamnesecomplete = useMemo(
    () => get(pcase, `course.${product.id}.anamnese.complete`, false),
    [pcase]
  );
  let testuser =
    (get(account, "email", "") || "").endsWith("nutrick24.de") ||
    (get(account, "email", "") || "").endsWith("b-coding.de") ||
    (get(account, "email", "") || "").endsWith("test.de");

  const paytest = (get(account, "email", "") || "").endsWith(
    "nutrick24.paytest"
  );
  const savePcase = useCallback(
    (attr, val) => {
      const pc = produce(pcase, (draft) => {
        set(draft, attr, val);
      });
      setPcase(pc);
      AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc);
    },
    [cognitoUser, pcase, setPcase]
  );

  const setVideoOK = useCallback(
    (week) => {
      savePcase(`course.${product.id}.video.${week}.ok`, true);
    },
    [savePcase]
  );

  return (
    <div>
      {paymentIndex < 0 && !testuser ? (
        <CompS>
          {account && (
            <>
              {disclaimerOK && disclaimerDone ? (
                // -----------------------
                // --------------> Payment
                // -----------------------
                <>
                  <div id="intro">
                    <p>
                      In diesem flexiblen Onlinekurs lernen Sie Schritt für
                      Schritt, wie Sie Ihre Ernährungs- und
                      Bewegungsgewohnheiten dauerhaft verbessern und ein
                      gesundes Gewicht erreichen, langfristig halten und so die
                      Entstehung von Übergewicht vermeiden können.
                    </p>
                    <h4>Ihre Vorteile:</h4>
                    <ul>
                      <li>
                        Vermeidung & Reduktion von Übergewicht: Lernen Sie, wie
                        Sie mit einer ausgewogenen Ernährung und einer passenden
                        Mahlzeitenstruktur Ihr Wunschgewicht erreichen und
                        dauerhaft halten können.
                      </li>
                      <li>
                        Wissen für den Alltag: Erhalten Sie fundierte
                        Informationen zu gesunder Ernährung und hilfreiche Tipps
                        für die Umsetzung im Alltag.
                      </li>
                      <li>
                        Bewegung und Sport integrieren: Finden Sie heraus, wie
                        Sie mit der richtigen körperlichen Aktivität Ihre
                        Gesundheit fördern und Gewicht verlieren können.
                      </li>
                      <li>
                        Flexible Kursgestaltung: Der Kurs ist zeitlich und
                        örtlich ungebunden. Sie können jederzeit starten und
                        lernen in Ihrem eigenen Tempo.
                      </li>
                      <li>
                        Abwechslungsreiche Lerninhalte: Profitieren Sie von
                        Video-Lektionen, Rezepten, praktischen Aufgaben und
                        einem digitalen Ernährungs- und Bewegungstagebuch.
                      </li>
                      <li>
                        Individuelle Betreuung: Bei Fragen steht Ihnen ein
                        qualifiziertes Team zur Verfügung.
                      </li>
                      <li>
                        Förderung Ihrer Gesundheit: Neben der Gewichtsabnahme
                        trägt der Kurs auch zur Verbesserung Ihrer allgemeinen
                        Gesundheit und Ihres Wohlbefindens bei.
                      </li>
                      <li>
                        Krankenkassenzuschuss möglich: Informieren Sie sich bei
                        Ihrer Krankenkasse, ob diese den Kurs bezuschusst.
                      </li>
                    </ul>
                    <p>
                      Mit meinem Online-Präventionskurs machen Sie den ersten
                      Schritt in ein gesünderes und selbstbestimmtes Leben!
                    </p>
                    <p>Kursgebühr: 150€</p>
                    <p>Ort: Online</p>
                    <p>Dauer: 8 Wochen</p>
                  </div>
                  <PaypalPayment
                    productId={product.id}
                    productName={product.name}
                    productPrice={paytest ? 0.01 : product.price}
                  />
                </>
              ) : (
                // --------------------------
                // --------------> Disclaimer
                // --------------------------
                <div id="disclaimer">
                  <div>
                    <p>
                      Hiermit bestätige ich, dass meine Teilnahme am
                      Präventionskurs freiwillig und auf eigene Gefahr erfolgt.
                      Die Kursleiterin übernimmt keine Haftung für
                      gesundheitliche Folgeschäden. Deshalb ist die
                      wahrheitsgemäße Beantwortung der folgenden Fragen
                      („Ausschlusskriterien für die Teilnahme am
                      Präventionskurs“) in den Check-Boxen äußerst wichtig und
                      verpflichtend. Weitere Informationen entnehmen Sie bitte
                      den allgemeinen Geschäftsbedingungen (AGBs).
                    </p>
                    <p>
                      Ausschlusskriterien für die Teilnahme am Präventionskurs
                      „Mit Leichtigkeit die Schwere los“:
                      <br />
                      <b>
                        Um sicherzustellen, dass unsere Kurse für alle
                        Teilnehmer geeignet und sicher sind, bitten wir Sie, die
                        folgenden Fragen zu beantworten:
                      </b>
                    </p>
                  </div>
                  <div id="dsce">
                    {disclaimerElements.map((element, index) => (
                      <KKRadio
                        key={index}
                        onClick={() =>
                          handleDisclaimerChange(index, !element.checked)
                        }
                      >
                        <Icon
                          name={
                            element.checked ? "check square" : "square outline"
                          }
                        />
                        <label>{element.text}</label>
                      </KKRadio>
                    ))}
                  </div>
                  <div>
                    <h4>Bitte beachten Sie:</h4>
                    <ul>
                      <li>
                        Personen mit einem BMI von ≥ 35 oder mit
                        behandlungsbedürftigen Erkrankungen sind grundsätzlich
                        von der Teilnahme an den Kursen ausgeschlossen.
                      </li>
                      <li>
                        Personen mit einem BMI zwischen 25 und 30 dürfen nur
                        nach ärztlicher Rücksprache am Kurs teilnehmen.
                      </li>
                      <li>
                        Kindern und Jugendlichen, die das 18. Lebensjahr noch
                        nicht erreicht haben, sind ebenfalls von der Teilnahme
                        ausgeschlossen.
                      </li>
                      <li>
                        Im Rahmen des Kurses wird nicht speziell auf
                        Lebensmittelunverträglichkeiten (z.B.
                        Fruktoseintoleranz, Laktoseintoleranz,
                        Histaminintoleranz, Zöliakie) eingegangen.
                      </li>
                      <li>
                        Ich nehme zur Kenntnis, dass der Kurs nicht explizit für
                        Schwangere oder Stillende geeignet ist.
                      </li>
                      <li>
                        Die Teilnahme an den Kursen erfolgt auf eigene
                        Verantwortung.
                      </li>
                      <li>
                        Bei Unsicherheiten oder Fragen wenden Sie sich bitte vor
                        Kursbeginn an uns.
                      </li>
                    </ul>
                    <p>
                      Indem Sie die Teilnahme an den Kursen bestätigen, erklären
                      Sie, dass Sie die Ausschlusskriterien gelesen und
                      verstanden haben und diese erfüllen.
                    </p>
                  </div>
                  {disclaimerOK && (
                    <div className="centered">
                      <KKButton
                        color={COLORS.ORANGE}
                        onClick={() => setDisclaimerDone(true)}
                      >
                        Weiter
                      </KKButton>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </CompS>
      ) : (
        // --------------------------------
        // --------------> Anamnese & Weeks
        // --------------------------------
        <div>
          <div className="info">
            <InfoBox
              visible={
                !get(pcase, `course.${product.id}.msg_welcome.dismissed`, false)
              }
              dismiss={() => {
                setPcase(
                  produce(pcase, (draft) => {
                    set(
                      draft,
                      `course.${product.id}.msg_welcome.dismissed`,
                      true
                    );
                  })
                );
              }}
            >
              Vielen Dank, dass Sie den Onlinekurs `Mit Leichtigkeit die Schwere
              los` gebucht haben. Wir freuen uns, Sie in den nächsten 8 Wochen
              zu begleiten.
            </InfoBox>
            {!anamnesecomplete && (
              <InfoBox visible>
                Bitte füllen Sie im ersten Schritt den Anamnese-Fragebogen aus.
                Anschließend wird die 1. Einheit freigeschaltet.
              </InfoBox>
            )}
          </div>
          <ul>
            <li
              className={anamnesecomplete ? "inactive" : "active"}
              onClick={() => {
                !anamnesecomplete && goto(`/anamnese/${product.id}`);
              }}
            >
              <div>
                <Icon name="doctor" />
                Anamnese
              </div>{" "}
              {get(pcase, `course.${product.id}.anamnese.complete`, false) && (
                <Icon color="green" name="check" />
              )}
            </li>
            {anamnesecomplete &&
              (get(pcase, `course.${product.id}.quiz.w1.ok`, false) ||
                testuser) && (
                <li
                  onClick={() => {
                    goto(`/protocols/sport/${getToday()}`);
                  }}
                >
                  <div>
                    <Icon name="bicycle" />
                    Bewegungstagebuch
                  </div>
                </li>
              )}
            {anamnesecomplete && (
              <li
                onClick={() => {
                  goto(`/protocols/eat/${getToday()}`);
                }}
              >
                <div>
                  <Icon name="food" />
                  Ernährungstagebuch
                </div>
              </li>
            )}
            <li
              onClick={() => {
                goto(`/protocols/weight/${getToday()}`);
              }}
            >
              <div>
                <Icon name="weight" />
                Gewichtstagebuch
              </div>
            </li>

            {anamnesecomplete && (
              <>
                {weekData.map((week, index) => {
                  const quizDone = get(
                    pcase,
                    `course.${product.id}.quiz.${week.id}.ok`,
                    false
                  );
                  const videoDone = get(
                    pcase,
                    `course.${product.id}.video.${week.id}.ok`,
                    false
                  );
                  const prevWeek = weekData[index - 1] || {};
                  const quizDonePrev = get(
                    pcase,
                    `course.${product.id}.quiz.${prevWeek.id}.ok`,
                    false
                  );
                  let prevOk =
                    get(prevWeek, "quiz.exists", false) &&
                    get(prevWeek, "quiz.required", false) &&
                    quizDonePrev;
                  const ok =
                    (startDate &&
                      getToday() >=
                        getDay8PlusDays(startDate || "20000101", index * 7) &&
                      (index === 0 || prevOk)) ||
                    testuser;
                  return (
                    <Section
                      key={week.name}
                      index={index}
                      week={week}
                      ok={ok}
                      course={product.id}
                      quizDone={quizDone}
                      videoDone={videoDone}
                      setVideoOK={setVideoOK}
                    />
                  );
                })}
              </>
            )}
            <InfoSection
              icon="info circle"
              title="Weiterführende Gesundheitsinformationen"
            >
              <p>
                Unter folgenden Links erhalten Sie weiterführende
                Gesundheitsinformationen. Diese sind optional und nicht
                Bestandteil des Präventionskurses. In Einheit 9 werden wir die
                Quellen kurz vorstellen und Sie werden lernen, wie Sie
                vertrauenswürdige Ernährungsempfehlungen im Internet erkennen.
              </p>
              <div className="files">
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.dge.de/gesunde-ernaehrung/gut-essen-und-trinken/dge-empfehlungen/"
                    target="_blank"
                  >
                    https://www.dge.de/gesunde-ernaehrung/gut-essen-und-trinken/dge-empfehlungen/
                    Letzte Einsicht 01.08.2024 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.dge.de/gesunde-ernaehrung/gut-essen-und-trinken/dge-ernaehrungskreis/"
                    target="_blank"
                  >
                    https://www.dge.de/gesunde-ernaehrung/gut-essen-und-trinken/dge-ernaehrungskreis/
                    Letzte Einsicht 01.08.2024 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.dge.de/"
                    target="_blank"
                  >
                    https://www.dge.de/ Letzte Einsicht 30.05.2023 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.dge.de/"
                    target="_blank"
                  >
                    https://www.dge.de/ Letzte Einsicht 30.05.2023 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.bzfe.de/"
                    target="_blank"
                  >
                    https://www.bzfe.de/ Letzte Einsicht 30.05.2023 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.bmel.de/DE/Home/home_node.html"
                    target="_blank"
                  >
                    https://www.bmel.de/DE/Home/home_node.html Letzte Einsicht
                    30.05.2023 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://adipositas-gesellschaft.de/dag/"
                    target="_blank"
                  >
                    https://adipositas-gesellschaft.de/dag/ Letzte Einsicht
                    30.05.2023 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.bfr.bund.de/de/start.html"
                    target="_blank"
                  >
                    https://www.bfr.bund.de/de/start.html Letzte Einsicht
                    30.05.2023 12:00
                  </a>{" "}
                </div>
                <div>
                  <Icon name="globe" />
                  <a
                    rel="noreferrer"
                    href="https://www.bzfe.de/nachhaltiger-konsum/orientierung-beim-einkauf/der-saisonkalender/app-der-saisonkalender/"
                    target="_blank"
                  >
                    https://www.bzfe.de/nachhaltiger-konsum/orientierung-beim-einkauf/der-saisonkalender/app-der-saisonkalender/
                    Letzte Einsicht 30.05.2023 12:00
                  </a>{" "}
                </div>
              </div>
            </InfoSection>
          </ul>
        </div>
      )}
    </div>
  );
};
export default Comp;

const InfoSection = (props) => {
  const { icon, title } = props;
  const [open, setOpen] = useState(false);
  return (
    <SectionS className={`${open ? "open" : "closed"}`}>
      <div
        className="sheader"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div>
          {icon && <Icon name={icon} />}
          {title}
        </div>
      </div>
      <div
        className={`${open ? "open" : "closed"} scontent`}
        onContextMenu={(event) => {
          event.preventDefault();
        }}
      >
        {props.children}
      </div>
    </SectionS>
  );
};
const Section = (props) => {
  const {
    week = {},
    ok = false,
    quizDone,
    course,
    setVideoOK = () => {},
    videoDone = false,
  } = props;
  const [open, setOpen] = useState(false);
  const goto = useNavigate();
  const playerEventHandler = (msg) => {
    if (msg === "end") {
      setVideoOK(week.id);
    }
  };
  return (
    <SectionS
      className={`${open ? "open" : "closed"} ${ok ? "active" : "inactive"}`}
    >
      <div
        className="sheader"
        onClick={() => {
          ok && setOpen(!open);
        }}
      >
        <div>
          <Icon name="tasks" />
          {week.name} - <span>{week.description}</span>
        </div>
        {quizDone && <Icon color="green" name="check" />}
        {!ok && <Icon name="lock" />}
      </div>
      {open && (
        <div
          className={`${open ? "open" : "closed"} scontent`}
          onContextMenu={(event) => {
            event.preventDefault();
          }}
        >
          <div style={{ color: "red", background: "white" }}>
            Zum Testen der Freischaltfunktion sind die Video-buttons innerhalb
            des Videos aktuell aktiv. Später sind nur noch die weissen Buttons
            unterhalb des Videos aktiv.
          </div>
          {get(week, "videos", []).map((v, index) => (
            <CustomStreamPlayer
              key={index}
              controls={true}
              src={v.videoid}
              cb={playerEventHandler}
              poster={`https://files.nutrick24.de/${v.poster}`}
            />
          ))}
          {!videoDone && (
            <div class="info">
              Die Kursunterlagen und das Quiz werden freigeschaltet, nachdem das
              Video vollständig angesehen wurde.
            </div>
          )}
          {videoDone && (
            <div className="files">
              {get(week, "files", []).map((f, index) => (
                <div key={f.file}>
                  <Icon name="file pdf" />
                  <a
                    rel="noreferrer"
                    href={`https://files.nutrick24.de/${f.file}`}
                    target="_blank"
                  >
                    {f.name}
                  </a>
                </div>
              ))}
            </div>
          )}
          {get(week, "quiz.exists") && videoDone && (
            <div
              className="quiz"
              onClick={() => {
                goto(`/quiz/${course}/${week.id}`);
              }}
            >
              <div>
                <Icon name="list alternate outline" />
                Quiz {!quizDone && "starten"}
              </div>{" "}
              {quizDone && <Icon color="green" name="check" />}
            </div>
          )}
        </div>
      )}
    </SectionS>
  );
};

const SectionS = styled.li`
  display: flex;
  flex-direction: column;
  .scontent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &.closed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.5s 0.2s linear, opacity 0.5s linear;
      opacity: 0;
      padding: 0;
    }
    &.open {
      max-height: auto;
      transition: max-height 0.5s linear, opacity 0.5s 0.5s linear;
      opacity: 100;
      padding: 1rem 0 0 0;
    }
  }
  .sheader {
    display: flex;
    justify-content: space-between;
    margin: -1rem 0 -1rem 0;
    padding: 1rem 0;
    &.open {
      margin: -1rem 0 1rem 0;
    }
  }
  &.open {
    background: rgba(255, 255, 255, 0.1);

    video {
      max-width: 100%;
      min-height: 56%;
    }
    .files {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.5rem;
      > * {
        font-size: 1.1rem;
      }
      a {
        text-decoration: none !important;
      }
      > div {
        display: flex;
        flex-direction: row;
        i {
          margin-right: 1rem;
        }
      }
    }
    .quiz {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 1rem;
      > * {
        font-size: 1.1rem;
      }
      padding: 0.5rem 0;
      margin-top: 0.5rem;
      border-top: 1px solid rgba(255, 255, 255, 0.2);

      i:first-child {
        margin-right: 1rem;
      }
    }
  }
`;

const weekData = [
  {
    id: "w1",
    name: "Einheit 1",
    description: "Übergewicht",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e1_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Eierkuchen-Basis-Rezept & Gurken-Joghurt-Dip",
        file: "praevention_2024-001/rezepte/e1_rezept01.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_01.m3u8",
        poster: "praevention_2024-001/videos/e1_videostart.png",
        videoid: "6a49ab8a62d2989962a63638a7ce2ec7",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w2",
    name: "Einheit 2",
    description: "Stoffwechsel",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e2_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Rote-Beeten-Beeren-Smoothie & Nährstoffreiche Bowl",
        file: "praevention_2024-001/rezepte/e2_rezept01.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_02.m3u8",
        poster: "praevention_2024-001/videos/e2_videostart.png",
        videoid: "964604c24627b98b09135ade9d0067ba",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w3",
    name: "Einheit 3",
    description: "Diäten",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e3_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Marinierter Tofu & Bircher-Müsli",
        file: "praevention_2024-001/rezepte/e3_rezept01.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_03.m3u8",
        poster: "praevention_2024-001/videos/e3_videostart.png",
        videoid: "6930f99ac1ddaca8b9a676afee767431",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w4",
    name: "Einheit 4",
    description: "Nahrungszusammensetzung",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e4_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Chia-Pudding & Wasser-Varianten",
        file: "praevention_2024-001/rezepte/e4_rezept01.pdf",
      },
      {
        name: "Mineralstofftabelle",
        file: "praevention_2024-001/e4_mineralstofftabelle.pdf",
      },
      {
        name: "Vitamintabelle",
        file: "praevention_2024-001/e4_vitamintabelle.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
      {
        name: "Infoblatt DGE-Empfehlungen",
        file: "praevention_2024-001/Infoblatt-DGE-Empfehlungen-2024.pdf",
      },
      {
        name: "Infoblatt-DGE-Ernährungskreis",
        file: "praevention_2024-001/Infoblatt-DGE-Ernaehrungskreis-2024.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_04.m3u8",
        poster: "praevention_2024-001/videos/e4_videostart.png",
        videoid: "2f7297302dac3551d6f9e4733bc2a1b5",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w5",
    name: "Einheit 5",
    description: "Mahlzeitenstruktur und Heißhunger",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e5_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Selbstgemachtes Fruchteis & Eis am Stiel",
        file: "praevention_2024-001/rezepte/e5_rezept01.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_05.m3u8",
        poster: "praevention_2024-001/videos/e5_videostart.png",
        videoid: "f527e9b48631f3907a5afb7b43f7d1e6",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w6",
    name: "Einheit 6",
    description: "Ernährungsmythen",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e6_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Bulgur-Salat & Avocadocreme",
        file: "praevention_2024-001/rezepte/e6_rezept01.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_06.m3u8",
        poster: "praevention_2024-001/videos/e6_videostart.png",
        videoid: "97128184098999faf2cb5408c31a61fe",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w7",
    name: "Einheit 7",
    description: "Ernährung und Psyche",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e7_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Linsen-Apfel-Salat & Kichererbsen-Curry",
        file: "praevention_2024-001/rezepte/e7_rezept01.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_07.m3u8",
        poster: "praevention_2024-001/videos/e7_videostart.png",
        videoid: "9e4fff0e577c6097569fc5853bb0f288",
      },
    ],
    quiz: { exists: true, required: true },
  },
  {
    id: "w8",
    name: "Einheit 8",
    description: "Schlank durch den Alltag",
    files: [
      {
        name: "Handout + Aufgaben",
        file: "praevention_2024-001/handouts/e8_handout_aufgaben.pdf",
      },
      {
        name: "Rezepte: Vollkornbrot selber backen & Wraps to go",
        file: "praevention_2024-001/rezepte/e8_rezept01.pdf",
      },
      {
        name: "Saisonkalender",
        file: "praevention_2024-001/e8_saisonkalender.pdf",
      },
      {
        name: "Ernährungsprotkoll",
        file: "praevention_2024-001/ernaehrungsprotokoll.pdf",
      },
      {
        name: "Bewegungsprotkoll",
        file: "praevention_2024-001/bewegungsprotokoll.pdf",
      },
    ],
    videos: [
      {
        name: "",
        file: "praevention_2024-001/videos/streams/2024-001_08.m3u8",
        poster: "praevention_2024-001/videos/e8_videostart.png",
        videoid: "ee6baa449dcd4ae8b678cd5143916a38",
      },
    ],
    quiz: { exists: true, required: true },
  },
];

const CompS = styled.div`
  p {
    margin: 0 0 1rem 0;
  }
  video {
    max-width: 100%;
  }
  .centered {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #disclaimer,
  #intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    i {
      margin-right: 1rem;
      width: 1.3em;
    }
    i,
    label {
      cursor: pointer;
    }
    ul {
      margin: 0.5rem 0;
      padding: 0;
      li {
        list-style-type: disc !important;
        list-style-position: inside;
        padding: 0 1rem;
        color: white;
        border: none !important;
        cursor: pointer;
        &.inactive {
          cursor: default;
          color: rgba(255, 255, 255, 0.5);
          border-color: rgba(255, 255, 255, 0.5);
        }
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        i:first-child {
          margin-right: 1rem;
        }
      }
    }
    #dsce {
      > * {
        margin-bottom: 1rem;
      }
    }
  }
`;
